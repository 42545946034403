import { GatsbySeo } from "gatsby-plugin-next-seo";
import React, { useEffect, useState } from "react";
import RightArrowWhite from "../assets/Icons/accordian_white.svg";
import RightArrow from "../assets/Icons/go.svg";
import Clm2Img from "../assets/Icons/mail.svg";
import Clm1Img from "../assets/Icons/phone.svg";
import ogimage from "../assets/Images/ogImages/OG_Thank_you-Whitepaper.png";
import BannerImage from "../assets/Images/ThankYou/Thankyou_whitepaper_banner.png";
import Button from "../components/Button";
import Layout from "../components/layouts/layout";
import DataService from "../services/api/data_services";
import { BASE_SITE_URL } from "../shared/constants";
import Miniview from "../templates/MiniView";
import TwoColumnContactInfo from "../templates/TwoColumnContactInfo";
import VideoBanner from "../templates/VideoBanner";

const ThankyouWhitePaper = () => {
  const [page, setPage] = useState(1);
  const [pdf, setPdf] = useState("");
  const [clientStoryData, setClientStoryData] = useState([]);
  const [clientStoryRawData, setClientStoryRawData] = useState([]);
  const [isHover, setIsHover] = useState(false);
  const urlParams =
    typeof window !== `undefined` &&
    new URLSearchParams(window.location.search);
  const myParam = typeof window !== `undefined` && urlParams.get("title");

  const toggleHover = () => {
    setIsHover(!isHover);
  };

  useEffect(() => {
    setPdf(localStorage.getItem("pdf_file"));
  }, []);

  useEffect(() => {
    DataService.getData(
      `/admin/api/post?limit=${3}&page=${page}&page_id=1&type=Client Story`
    ).then((response) => {
      setClientStoryData([...clientStoryData, ...response.data]);
      setClientStoryRawData(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  /**
   * Video Banner
   */

  const VideobannerData = {
    variant: "contained",
    color: "primary",
    label: "DOWNLOAD NOW",
    ImageBannerTitle: "Thank You!",
    ImageBannerSubTitle: (
      <>
        for downloading “{myParam}”. We hope you enjoy reading our white paper.
        <div className="white-paper-margin">
          If you can’t wait to check your email
        </div>
      </>
    ),
    BannerImage: BannerImage,
    route: pdf,
  };

  /**
   * TwoColumnContactInfo
   */

  const TwoColumnContactInfoData = {
    Clm1Img: Clm1Img,
    Clm1Title: "Phone:",
    Clm1SubTitle: "+44 (0) 161 706 7983",
    Clm2Img: Clm2Img,
    Clm2Title: "Email:",
    Clm2SubTitle: "hello@digiryte.com",
  };

  return (
    <Layout
      seo={{
        title: "Thank You",
        ogtitle: "Thank You",
        description: "Welcome to Digiryte Thank You Page",
        ogimage: ogimage,
        ogurl: `${BASE_SITE_URL}/thank-you-white-paper`,
      }}
    >
      <GatsbySeo noindex={true} />
      <VideoBanner
        VideobannerData={VideobannerData}
        wrapClassName="custom-banner-wrap"
      />

      {clientStoryData && clientStoryData.length > 0 && (
        <React.Fragment>
          {" "}
          <div className="mt-70 body-container">
            <div className="body-container mt-70">
              <h2 className="dynamic-title-header ttc">
                <span className="red-line3letter">Rec</span>ommended Client
                stories
              </h2>
            </div>
          </div>
          <div className="mt-50">
            <Miniview miniviewData={clientStoryData} />
          </div>
        </React.Fragment>
      )}

      <div style={{ width: "100%", textAlign: "center" }}>
        <div
          onMouseEnter={toggleHover}
          onMouseLeave={toggleHover}
          className="mt-60"
          role="presentation"
          onClick={() => setPage(page + 1)}
          style={{ display: "inline-block" }}
        >
          {clientStoryRawData.has_more && (
            <Button
              icon={
                <img
                  className="effect-btn-svgicon"
                  src={!isHover ? RightArrow : RightArrowWhite}
                  alt="MORE CLIENT STORIES"
                />
              }
              customClassName="info-view-resize-btn"
              color="primary"
              variant="outlined"
              label="MORE CLIENT STORIES"
            />
          )}
        </div>
      </div>
      <div className="mt-70">
        <TwoColumnContactInfo
          TwoColumnContactInfoData={TwoColumnContactInfoData}
        />
      </div>
    </Layout>
  );
};

export default ThankyouWhitePaper;
